<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filterByCompanyType"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'operationgrid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'operation',
			userBerthIds: [],
			modelColumnDefs: {},
			stevedoreCompanyType: 4
		};
	},
	mounted() {
		this.userBerthIds = this.$store.getters.getUserBerthIds;
	},
	computed: {
		filterByCompanyType() {
			const userCompanyId = this.$store.state.session.properties.COMPANY_ID;
			const userCompanyType = this.$store.state.session.properties.COMPANY.companytypeid;

			if (userCompanyType && userCompanyType === this.stevedoreCompanyType) {
				return {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'stevedorecompany', op: 'eq', data: userCompanyId },
						{ field: 'locationid', op: 'in', data: this.userBerthIds }
					]
				};
			} else {
				return {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'locationid', op: 'in', data: this.userBerthIds }]
				};
			}
		}
	}
};
</script>
